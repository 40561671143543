import { BasicsClass } from '@/common/BasicsClass';
export class CouponsDyManageAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getDouyinCoupons', {
            url: '/admin/coupons/douyinList',
            name: 'list',
            label: '列表'
        }],
        ['addDouyinCoupons', {
            url: '/admin/coupons/douyinAdd',
            name: 'add',
            label: '添加'
        }],
        ['updateDouyinCoupons', {
            url: '/admin/coupons/douyinEdit',
            name: 'edit',
            label: '编辑'
        }],
        ['delteDouyinCouponItem', {
            url: '/admin/coupons/douyinDelete',
            name: 'delete',
            label: '删除'
        }],
        ['searchVenueList', {
            url: '/admin/coupons/shop/list',
            name: 'shop-list',
            label: '店铺列表',
            // hidden: true
        }],
        ['getCouponTimeDurations', {
            url: '/admin/coupons/timeList',
            name: 'coupon-time',
            label: '优惠券可用时段',
            // hidden: true
        }],
        ['poolTableCategory', {
            url: '/admin/poolTableCategory/list',
            name: 'pool-category',
            label: '台桌/包厢类型',
            // hidden: true
        }],
        ['getPreviewFileTable', {
            url: '/admin/couponsShopImport/preview',
            name: 'import-shop-preivew',
            label: '导入店铺预览列表',
        }],
    ])


    // 新增抖音优惠券
    public addDouyinCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addDouyinCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 修改抖音优惠券
    public updateDouyinCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('updateDouyinCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 抖音优惠券列表
    public getDouyinCoupons(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getDouyinCoupons').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 删除抖音优惠券
    public delteDouyinCouponItem(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('delteDouyinCouponItem').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 搜索店铺列表
    public searchVenueList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('searchVenueList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


    //卡券时间段列表
    public getCouponTimeDurations(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getCouponTimeDurations').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //球桌分类列表
    public poolTableCategory(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('poolTableCategory').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    //导入店铺预览列表
    public getPreviewFileTable(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getPreviewFileTable').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }
}